




import { Component, Vue } from 'vue-property-decorator'
import TopV3 from '@/views/teacher/v3/setting/Top.vue'
import Top from '@/views/teacher/setting/Top.vue'

@Component({
  components: {
    TopV3,
    Top,
  },
})
export default class SettingTopContainer extends Vue {
  private isV3 = Vue.prototype.$gdlsCookiesV3.isV3()
}
