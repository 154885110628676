










import { Component, Vue } from 'vue-property-decorator'
import ColoredBox from '@/components/atoms/ColoredBox.vue'
import TitleBase from '@/components/atoms/TitleBase.vue'
import TitleTextBase from '@/components/atoms/TitleTextBase.vue'

@Component({
  components: {
    ColoredBox,
    TitleBase,
    TitleTextBase,
  },
})
export default class Top extends Vue {
  private menuDatas = [
    { url: '/teacher/setting/student', name: '生徒設定' },
    { url: '/teacher/setting/timetable', name: '時間割設定' },
    { url: '/teacher/setting/school', name: '学校設定' },
    { url: '/teacher/setting/default-exit-condition', name: 'デフォルト終了条件設定' },
    { url: '/teacher/setting/attribute', name: '任意属性設定' },
    { url: '/teacher/setting/lesson', name: '授業設定' },
    { url: '/teacher/setting/class-name', name: '集団クラス名設定' },
    { url: '/teacher/setting/seminar', name: '講習会設定' },
    { url: '/teacher/setting/teacher', name: '講師・メンター設定' },
    { url: '/teacher/setting/student-service', name: '生徒利用可能サービス設定' },
    { url: '/teacher/setting/other', name: 'その他設定' },
  ]

  private breadcrumbs = [{ text: '設定', active: true }]
}
