import { render, staticRenderFns } from "./Top.vue?vue&type=template&id=4fe9238e&scoped=true&lang=pug&"
import script from "./Top.vue?vue&type=script&lang=ts&"
export * from "./Top.vue?vue&type=script&lang=ts&"
import style0 from "./Top.vue?vue&type=style&index=0&id=4fe9238e&lang=stylus&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4fe9238e",
  null
  
)

export default component.exports